.leaflet-container {
  width: 100%;
  height: 100vh;
}


/*Wraperclass for the divicon*/
.map-label {
  position: absolute;
  bottom: 0;left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*Wrap the content of the divicon (text) in this class*/
.map-label-content {
  order: 1;
  position: relative; left: -50%;
  background-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #444;
  padding: 3px;
  white-space: nowrap;
}
/*Add this arrow*/
.map-label-arrow {
  order: 2;
  width: 0px; height: 0px; left: 50%;
  border-style: solid;
  border-color: #444 transparent transparent transparent;
  border-width: 10px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
  margin-left: -6px;
}